import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Transition from '../utils/Transition.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faChild, faGlobeAmericas, faHeadSideCoughSlash } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next';


function Programs() {

  const { t } = useTranslation();


  const [tab, setTab] = useState(1);

  const tabs = useRef(null);

  const heightFix = () => {
    if (tabs.current.children[tab]) {
      tabs.current.style.height = tabs.current.children[tab - 1].offsetHeight + 'px'
    }
  }

  useEffect(() => {
    heightFix()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab])

  return (
    <div className="relative" data-aos="zoom-y-out" data-aos-delay="250">

      {/* Section background (needs .relative class on parent and next sibling elements) */}
      <div className="absolute inset-0 pointer-events-none mb-16" aria-hidden="true"></div>

      <div className="relative">

          <div className="col-2-md thirty-70">


            {/* Tabs items */}
            <div className="" data-aos="zoom-y-out" ref={tabs}>
              <div className="relative flex flex-col text-center lg:text-right">
                {/* Item 1 */}
                <Transition
                  show={tab === 1}
                  appear={true}
                  className="w-full center"
                  enter="transition ease-in-out duration-700 transform order-first"
                  enterStart="opacity-0 translate-y-16"
                  enterEnd="opacity-100 translate-y-0"
                  leave="transition ease-in-out duration-300 transform absolute"
                  leaveStart="opacity-100 translate-y-0"
                  leaveEnd="opacity-0 -translate-y-16"
                >
                  {/* <div className="itap-iphone"></div> */}
                  <div className="relative inline-flex flex-col">
                    {/* <img className="iphone-mockup" src={require('../images/logos/digital-itap-logo.svg').default} alt="Features bg" /> */}
                    <Link className="program-logo" target="_blank" to="/itap">
                      <img className="iphone-mockup" src={require('../images/mockup-itap.png').default} alt="Features bg" />
                    </Link>
                  </div>
                </Transition>
                {/* Item 2 */}
                {/* <Transition
                  show={tab === 2}
                  appear={true}
                  className="w-full center"
                  enter="transition ease-in-out duration-700 transform order-first"
                  enterStart="opacity-0 translate-y-16"
                  enterEnd="opacity-100 translate-y-0"
                  leave="transition ease-in-out duration-300 transform absolute"
                  leaveStart="opacity-100 translate-y-0"
                  leaveEnd="opacity-0 -translate-y-16"
                >
                  <div className="relative inline-flex flex-col">
                  <a target="_blank" rel="noreferrer" href="https://covid19testus.org/research-studies/test-us-at-home/how-to-enroll/" className="program-logo">
                    <img className="iphone-mockup" src={require('../images/mockup-itap-feasibility.png').default} alt="Features bg" />
                    </a>
                  </div>
                </Transition> */}
                <Transition
                  show={tab === 3}
                  appear={true}
                  className="w-full center"
                  enter="transition ease-in-out duration-700 transform order-first"
                  enterStart="opacity-0 translate-y-16"
                  enterEnd="opacity-100 translate-y-0"
                  leave="transition ease-in-out duration-300 transform absolute"
                  leaveStart="opacity-100 translate-y-0"
                  leaveEnd="opacity-0 -translate-y-16"
                >
                  <div className="relative inline-flex flex-col">
                  <a target="_blank" rel="noreferrer" href="https://covid19testus.org/research-studies/test-us-at-home/how-to-enroll/" className="program-logo">
                    <img className="iphone-mockup" src={require('../images/tuah-iphone.png').default} alt="Features bg" />
                    </a>
                  </div>
                </Transition>
                <Transition
                  show={tab === 4}
                  appear={true}
                  className="w-full center"
                  enter="transition ease-in-out duration-700 transform order-first"
                  enterStart="opacity-0 translate-y-16"
                  enterEnd="opacity-100 translate-y-0"
                  leave="transition ease-in-out duration-300 transform absolute"
                  leaveStart="opacity-100 translate-y-0"
                  leaveEnd="opacity-0 -translate-y-16"
                >
                  <div className="relative inline-flex flex-col">
                  <a target="_blank" rel="noreferrer" href="/stop-covid" className="program-logo">
                    <img className="iphone-mockup" src={require('../images/stop-iphone.png').default} alt="Features bg" />
                    </a>
                  </div>
                </Transition>
                <Transition
                  show={tab === 5}
                  appear={true}
                  className="w-full center"
                  enter="transition ease-in-out duration-700 transform order-first"
                  enterStart="opacity-0 translate-y-16"
                  enterEnd="opacity-100 translate-y-0"
                  leave="transition ease-in-out duration-300 transform absolute"
                  leaveStart="opacity-100 translate-y-0"
                  leaveEnd="opacity-0 -translate-y-16"
                >
                  <div className="relative inline-flex flex-col">
                    {/* <img className="iphone-mockup" src={require('../images/logos/digital-itap-logo.svg').default} alt="Features bg" /> */}
                    <a target="_blank" rel="noreferrer" href="https://sayyeshometest.org/" className="program-logo">
                    <img className="iphone-mockup" src={require('../images/sayyes-iphone.png').default} alt="Features bg" />
                    </a>
                  </div>
                </Transition>
              </div>
            </div >
            {/* end tabs */}


            {/* Table */}
            <div style={{overflow:'auto'}}>
              <table className="table" data-aos="fade-right" data-aos-delay="250">
              <thead>
                <tr className="table-head">
                  <th>{t('programs-h01-01')}</th>
                  <th>{t('programs-h01-02')}</th>
                  <th>{t('programs-h01-03')}</th>
                  <th>{t('programs-h01-04')}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
              <tr
                className={`transition duration-300 ease-in-out ${tab !== 1 ? 'bg-white shadow-md border-gray-200 hover:shadow-lg' : 'bg-gray-200 border-transparent'}`}
                href="#0"
                onMouseOver={(e) => { e.preventDefault(); setTab(1); }}
              >
                <td>
                  <a target="_blank" rel="noreferrer" href="/itap">
                    {t('programs-itap-01')}
                  </a>
                </td>
                <td>
                  <a target="_blank" rel="noreferrer" href="/itap">
                    {t('programs-itap-02')}
                  </a>
                </td>
                <td>
                  <a target="_blank" rel="noreferrer" href="/itap">
                  <ul>
                    <li><FontAwesomeIcon icon={faGlobeAmericas}/><span> {t('programs-itap-03')}</span></li>
                    <li><FontAwesomeIcon  icon={faChild}/><span> {t('programs-itap-04')}</span></li>
                  </ul>
                  </a>
                </td>
                <td>
                  <a target="_blank" rel="noreferrer" href="/itap">
                  {t('programs-itap-05')}
                  </a>
                </td>
                <td>
                  <a target="_blank" rel="noreferrer" href="/itap"><FontAwesomeIcon icon={faArrowRight} /></a>
                </td>
              </tr>
              {/* <tr
                className={`transition duration-300 ease-in-out ${tab !== 2 ? 'bg-white shadow-md border-gray-200 hover:shadow-lg' : 'bg-gray-200 border-transparent'}`}
                href="#0"
                onMouseOver={(e) => { e.preventDefault(); setTab(2); }}
              >
                <td>
                  <a target="_blank" rel="noreferrer" href="/itap-feasibility">
                    Digital ITAP Feasibility
                  </a>
                </td>
                <td>
                  <a target="_blank" rel="noreferrer" href="/itap-feasibility">
                  2 tests over 2 days
                  </a>
                </td>
                <td>
                  <a target="_blank" rel="noreferrer" href="/itap-feasibility">
                  <ul>
                    <li><FontAwesomeIcon icon={faHeadSideCough} /><span>Have symptoms at time of enrollment</span></li>
                    <li><FontAwesomeIcon icon={faGlobeAmericas}/><span>Mainland US, except AZ</span></li>
                    <li><FontAwesomeIcon icon={faChild} /><span>2+ yrs old</span></li></ul>
                  </a>
                </td>
                <td>
                  <a target="_blank" rel="noreferrer" href="/itap-feasibility">
                  up to $25
                  </a>
                </td>
                <td>
                  <a target="_blank" rel="noreferrer" href="/itap-feasibility"><FontAwesomeIcon icon={faArrowRight} /></a>
                </td>
              </tr> */}

              {/* <tr
                className={`transition duration-300 ease-in-out  ${tab !== 3 ? 'bg-white shadow-md border-gray-200 hover:shadow-lg' : 'bg-gray-200 border-transparent'}`}
                href="#0"
                onMouseOver={(e) => { e.preventDefault(); setTab(3); }}
              >
                <td>
                  <a target="_blank" rel="noreferrer" href="https://covid19testus.org/research-studies/test-us-at-home/how-to-enroll/">
                    Test Us at Home
                  </a>
                </td>
                <td>
                  <a target="_blank" rel="noreferrer" href="https://covid19testus.org/research-studies/test-us-at-home/how-to-enroll/">
                  2 tests every other day for 2 weeks
                  </a>
                </td>
                <td>
                  <a target="_blank" rel="noreferrer" href="https://covid19testus.org/research-studies/test-us-at-home/how-to-enroll/">
                    <ul>
                      <li><FontAwesomeIcon icon={faHeadSideCoughSlash} /><span>No symptoms at time of enrollment</span></li>
                    <li><FontAwesomeIcon icon={faGlobeAmericas}/><span>Mainland US, except AZ</span></li>
                      <li><FontAwesomeIcon icon={faChild} /><span>2+ yrs old</span></li>
                    </ul>
                  </a>
                </td>
                <td>
                  <a target="_blank" rel="noreferrer" href="https://covid19testus.org/research-studies/test-us-at-home/how-to-enroll/">
                  up to $250
                  </a>
                </td>
                <td>
                  <a target="_blank" rel="noreferrer" href="https://covid19testus.org/research-studies/test-us-at-home/how-to-enroll/"><FontAwesomeIcon icon={faArrowRight} /></a>
                </td>
              </tr> */}

              <tr
                className={`transition duration-300 ease-in-out ${tab !== 4 ? 'bg-white shadow-md border-gray-200 hover:shadow-lg' : 'bg-gray-200 border-transparent'}`}
                href="#0"
                onMouseOver={(e) => { e.preventDefault(); setTab(4); }}
              >
                <td><a target="_blank" rel="noreferrer" href="/stop-covid">
                  {t('programs-stop-01')}
                  </a></td>
                <td><a target="_blank" rel="noreferrer" href="/stop-covid">
                  {t('programs-stop-02')}
                  </a></td>
                <td><a target="_blank" rel="noreferrer" href="/stop-covid">
                <ul>
                  <li><FontAwesomeIcon icon={faGlobeAmericas}/><span>{t('programs-stop-03')}</span></li>
                  <li><FontAwesomeIcon icon={faChild} /><span>{t('programs-stop-04')}</span></li>
                </ul>
                  </a></td>
                <td><a target="_blank" rel="noreferrer" href="/stop-covid">
                  {t('programs-stop-05')}
                  </a></td>
                <td><a target="_blank" rel="noreferrer" href="/stop-covid"><FontAwesomeIcon icon={faArrowRight} /></a></td>
              </tr>

              <tr
                className={`transition duration-300 ease-in-out ${tab !== 5 ? 'bg-white shadow-md border-gray-200 hover:shadow-lg' : 'bg-gray-200 border-transparent'}`}
                href="#0"
                onMouseOver={(e) => { e.preventDefault(); setTab(5); }}
              >
                <td><a target="_blank" rel="noreferrer" href="https://sayyeshometest.org/">
                  {t('programs-syct-01')}
                  </a></td>
                <td><a target="_blank" rel="noreferrer" href="https://sayyeshometest.org/">
                  {t('programs-syct-02')}
                  </a></td>
                <td><a target="_blank" rel="noreferrer" href="https://sayyeshometest.org/">
                <ul><li><FontAwesomeIcon icon={faGlobeAmericas} />
                <span>{t('programs-syct-03')}</span></li></ul>
                  </a></td>
                <td><a target="_blank" rel="noreferrer" href="https://sayyeshometest.org/">
                {t('programs-syct-04')}
                  </a></td>
                <td><a target="_blank" rel="noreferrer" href="https://sayyeshometest.org/"><FontAwesomeIcon icon={faArrowRight} /></a></td>
              </tr>
              </tbody>

              </table>
            </div>
            {/* end table wrap */}

          </div >

      </div >
    </div >
  );
}

export default Programs;
