import { useTranslation } from 'react-i18next';

const Language = () => {
  const { i18n } = useTranslation();

  function changeLanguage(e) {
    i18n.changeLanguage(e.target.parentElement.value);
  }

  return(
    <div className='language-switcher'>
      <button className="nav-link" onClick={changeLanguage} value='en'><span>English</span></button>
      <button className="nav-link" onClick={changeLanguage} value='es'><span>Español</span></button>
    </div>
  )
}

export default Language;

