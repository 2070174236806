import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandSparkles, faPeopleArrowsLeftRight, faSyringe, faVials, faMaskFace, faUsers } from '@fortawesome/free-solid-svg-icons'


function FeaturesBlocks() {
  return (
    <div className="relative">

      {/* Section background (needs .relative class on parent and next sibling elements) */}
      <div className="absolute inset-0 top-1/2 md:mt-24 lg:mt-0 bg-gray-900 pointer-events-none" aria-hidden="true"></div>
      <div className="relative">

          {/* Section header */}
          <div className="section-header center">
            <h2 className="h2 mb-4" data-aos="zoom-y-out" data-aos-delay="250">Prevent infection and reduce the spread</h2>
            <p className="text-xl " data-aos="zoom-y-out" data-aos-delay="250">Along with frequent at-home testing, there are many other things you can do to help prevent the spread of COVID-19 and protect your friends, family, and community.</p>
          </div>

          {/* Items */}
          <div className="max-w-sm mx-auto grid gap-6 md:grid-cols-2 lg:grid-cols-3 items-start md:max-w-2xl lg:max-w-none">

            {/* 1st item */}
            <div className="block relative flex flex-col items-center p-6 bg-white rounded shadow-xl" data-aos="zoom-y-out" data-aos-delay="250">
            <div className="features-icon center">
                <FontAwesomeIcon icon={faSyringe} />
              </div> 
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Get Vaccinated</h4>
              <p className=" text-center">Protect you and your community by <a href="https://www.cdc.gov/coronavirus/2019-ncov/vaccines/index.html" target="_blank">getting vaccinated</a>.</p>
            </div>

            {/* 2nd item */}
            <div className="block relative flex flex-col items-center p-6 bg-white rounded shadow-xl" data-aos="zoom-y-out" data-aos-delay="250">
            <div className="features-icon center">
                <FontAwesomeIcon icon={faVials} />
              </div> 
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Test Regularly</h4>
              <p className=" text-center">Know whether you need to isolate to <a href="https://www.cdc.gov/coronavirus/2019-ncov/your-health/quarantine-isolation.html" target="_blank">protect others</a>.</p>
            </div>

            {/* 3rd item */}
            <div className="block relative flex flex-col items-center p-6 bg-white rounded shadow-xl" data-aos="zoom-y-out" data-aos-delay="250">
            <div className="features-icon center">
                <FontAwesomeIcon icon={faMaskFace} />
              </div> 
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Wear a Mask</h4>
              <p className=" text-center"><a href="https://www.cdc.gov/coronavirus/2019-ncov/prevent-getting-sick/about-face-coverings.html" target="_blank">Masks</a> can help prevent the spread, especially indoors.</p>
            </div>

            {/* 4th item */}
            <div className="block relative flex flex-col items-center p-6 bg-white rounded shadow-xl" data-aos="zoom-y-out" data-aos-delay="250">
            <div className="features-icon center">
                <FontAwesomeIcon icon={faPeopleArrowsLeftRight} />
              </div>           
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Practice Physical Distancing</h4>
              <p className=" text-center">People without symptoms can still <a href="https://www.cdc.gov/coronavirus/2019-ncov/transmission/index.html" target="_blank">spread COVID-19</a>.</p>
            </div>

            {/* 5th item */}
            <div className="block relative flex flex-col items-center p-6 bg-white rounded shadow-xl" data-aos="zoom-y-out" data-aos-delay="250">
              <div className="features-icon center">
                <FontAwesomeIcon icon={faHandSparkles} />
              </div>
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Wash your Hands</h4>
              <p className=" text-center">Always <a href="https://www.cdc.gov/handwashing/index.html" target="_blank">wash your hands</a> after visiting public places.</p>
            </div>

            {/* 6th item */}
            <div className="block relative flex flex-col items-center p-6 bg-white rounded shadow-xl" data-aos="zoom-y-out" data-aos-delay="250">
              <div className="features-icon center">
                <FontAwesomeIcon icon={faUsers} />
              </div> 
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Spread the Word</h4>
              <p className=" text-center">Help others <a href="https://www.cdc.gov/coronavirus/2019-ncov/index.html" target="_blank">learn more</a> about how to stop the spread.</p>
            </div>

          </div>

      </div>
    </div>
  );
}

export default FeaturesBlocks;
