import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandSparkles, faPeopleArrowsLeftRight, faSyringe, faVials, faMaskFace, faUsers } from '@fortawesome/free-solid-svg-icons'


export default function ItapBlocks() {
  return (
    <div className="relative">

      {/* Section background (needs .relative class on parent and next sibling elements) */}
      {/* <div className="absolute inset-0 top-1/2 md:mt-24 lg:mt-0 bg-gray-900 pointer-events-none" aria-hidden="true"></div> */}
      <div className="relative">

          {/* Section header */}
          <div className="section-header center">
            <h2 className="h2 mb-4" data-aos="zoom-y-out" data-aos-delay="250">Are you eligible?</h2>
            <p className="text-xl " data-aos="zoom-y-out" data-aos-delay="250">See if you meet the below eligibility requirements before signing up.</p>
          </div>

          {/* Items */}
          <div className="max-w-sm mx-auto grid gap-6 md:grid-cols-2 lg:grid-cols-3 items-start md:max-w-2xl lg:max-w-none">

            {/* 1st item */}
            {/* <div className="block relative flex flex-col items-center p-6 bg-white rounded shadow-xl" data-aos="zoom-y-out" data-aos-delay="250">
              <div className="features-icon center">
                <FontAwesomeIcon icon={faHandSparkles} />
              </div>
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Symptoms in past 14 days</h4>
              <p className=" text-center">You have had COVID-19 symptoms within the last 14 days.</p>
            </div> */}

            <div className="block relative flex flex-col items-center p-6 bg-white rounded shadow-xl" data-aos="zoom-y-out" data-aos-delay="250">
            <div className="features-icon center">
                <FontAwesomeIcon icon={faSyringe} />
              </div> 
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">No positive tests</h4>
              <p className=" text-center">You have not tested positive for COVID-19 in the last 30 days.</p>
            </div>

            <div className="block relative flex flex-col items-center p-6 bg-white rounded shadow-xl" data-aos="zoom-y-out" data-aos-delay="250">
            <div className="features-icon center">
                <FontAwesomeIcon icon={faVials} />
              </div> 
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">FedEx-accessible</h4>
              <p className=" text-center">You can arrange a FedEx pickup, or visit a FedEx drop-off site.</p>
            </div>

            <div className="block relative flex flex-col items-center p-6 bg-white rounded shadow-xl" data-aos="zoom-y-out" data-aos-delay="250">
            <div className="features-icon center">
                <FontAwesomeIcon icon={faMaskFace} />
              </div> 
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Can receive shipments</h4>
              <p className=" text-center">You have a mailing address where you can receive shipments.</p>
            </div>

            <div className="block relative flex flex-col items-center p-6 bg-white rounded shadow-xl" data-aos="zoom-y-out" data-aos-delay="250">
            <div className="features-icon center">
                <FontAwesomeIcon icon={faPeopleArrowsLeftRight} />
              </div>           
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">Mainland US residence</h4>
              <p className=" text-center">You live in the mainland US (not Alaska, Hawaii, or Puerto Rico).</p>
            </div>

            <div className="block relative flex flex-col items-center p-6 bg-white rounded shadow-xl" data-aos="zoom-y-out" data-aos-delay="250">
              <div className="features-icon center">
                <FontAwesomeIcon icon={faUsers} />
              </div> 
              <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">At least 2 years old</h4>
              <p className=" text-center">You, or the participant, are at least 2 years old.</p>
            </div>

          </div>

      </div>
    </div>
  );
}
