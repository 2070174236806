import React, { useEffect} from 'react';
import { Link } from 'react-router-dom';
import Accordion from '../partials/accordion';
import Language from '../partials/language';
import Programs from '../partials/Programs';
import DownloadApp from '../partials/downloadApp';
import ItapBlocks from '../partials/itap-blocks';
import Footer from '../partials/Footer';
import { useTranslation } from 'react-i18next';
import TagManager from 'react-gtm-module';
const docTitle = "Digital ITAP - Independent Testing & Assessment Protocol"
const tagManagerArgs = {
    dataLayer: {
      event: 'page_view',
      page_location: document.location.href,
      page_title: docTitle
    }
}

function getFaviconEl() {return document.getElementById("favicon");}

function ITAP() {
  const { t } = useTranslation();
  TagManager.dataLayer(tagManagerArgs)
  useEffect(() => {
    document.title = docTitle; 
    
    const iTapFavicon = () => {
      const favicon = getFaviconEl();
      favicon.href = require('../images/logos/itap-favicon.ico').default
      console.log(favicon.href)
    };
    iTapFavicon()
  }, []);

  let language = ("uset",useTranslation().i18n.language)
  let appStoreButton, googlePlayButton;
  if (language === "en") {
    googlePlayButton = require('../images/download/android/en.svg').default;
    appStoreButton = require('../images/download/ios/en_black.svg').default;
  }
  if (language === "es") {
    googlePlayButton = require('../images/download/android/es.svg').default;
    appStoreButton = require('../images/download/ios/es_black.svg').default;
  }

  const panels = [
    {
      label: t('itap-q01'),
      content: t('itap-a01-01') 
        +'<ul>'
        +'<li>'
        +t('itap-a01-02')
        +'</li>'
        +'<li>'
        +t('itap-a01-03')
        +'</li>'
        +'</ul>'
    },
    {
      label: t('itap-q02'),
      content: t('itap-a02-01') 
      +'<ul>'
      +'<li>'
      +t('itap-a02-02')
      +'</li>'
      +'<li>'
      +t('itap-a02-03')
      +'</li>'
      +'<li>'
      +t('itap-a02-04')
      +'</li>'
      +'</ul>',
    },	
    {
      label: t('itap-q03'),
      content: t('itap-a03-01') 
      +'<ul>'
      +'<li>'
      +t('itap-a03-02')
      +'</li>'
      +'<li>'
      +t('itap-a03-03')
      +'</li>'
      +'<li>'
      +t('itap-a03-04')
      +'</li>'
      +'</ul>',
    },
    {
      label: t('itap-q04'),
      content: t('itap-a04-01') 
      +'<ul>'
      +'<li>'
      +t('itap-a04-02')
      +'</li>'
      +'<li>'
      +t('itap-a04-03')
      +'</li>'
      +'<li>'
      +t('itap-a04-04')
      +'</li>'
      +'</ul>',
    },
    {
      label: t('itap-q05'),
      content: t('itap-a05-01') 
      +'<ul>'
      +'<li>'
      +t('itap-a05-02')
      +'</li>'
      +'<li>'
      +t('itap-a05-03')
      +'</li>'
      +'</ul>'
    },
    {
      label: t('itap-q06'),
      content: '<ul>'
      +'<li>'
      + t('itap-a06-01')
      +'</li>'
      +'<li>'
      +t('itap-a06-02')
      +'</li>'
      +'<ul>'
      +'<li>'
      +t('itap-a06-03')
      +'</li>'
      +'<li>'
      +t('itap-a06-04')
      +'</li>'
      +'</ul>'
      +'</ul>'
    },
  ];

  return (
    <div className="flex flex-col min-h-screen overflow-hidden">
      <header className="">
          <nav className="" style={{justifyContent:'center'}}>
            <Link className="nav-link" to="/itap">
              <img src={require('../images/logos/digital-itap-logo.svg').default} style={{padding:'1rem 0', maxHeight:'200px', maxWidth:'300px'}} alt="ITAP logo" />
            </Link>
            {/* <Language style={{display:'none !important'}}/> */}
          </nav>
      </header>
      <main className="flex-grow">
               
      <section className="itap-hero dark-bg" data-aos="zoom-y-out" data-aos-delay="250">
          <div className="section-content">
            <div className="section-header center" data-aos-delay="100">
              <h1>Digital ITAP Study</h1>

              <h3>Help us learn more about the different ways to test for COVID-19 at home!</h3>
              <img className="itap-hero-image" src={require('../images/wear-masks.svg').default} alt="Features bg" />
            </div>
            <div className="">
            <small>With support from the National Institutes of Health Rapid Acceleration of Diagnostics (RADx<sup>SM</sup>) initiative</small>
            </div>
          </div>
        </section>


        <section className="">
        <div className="section-content">
          <div className="section-header center" data-aos="zoom-y-out" data-aos-delay="100" >
            <h2 className="">About Digital ITAP</h2>
          </div>
          <div className=" col-2-md" data-aos-delay="100" data-aos="fade-right" >
            <div>
              <p>{t('itap-a01-01')}
              <ul>
                  <li>{t('itap-a01-02')}</li>
                  <li>{t('itap-a01-03')}</li>
              </ul>
              </p>
            </div>

            <div data-aos-delay="100" data-aos="fade-left" >
              <img className="itap-hero-image" src={require('../images/pcr-vs-rapid.svg').default} alt="Features bg" />
            </div>
          </div> 
        </div>
        </section>
        <section className="dark-bg">
          <ItapBlocks />
        </section>
        <section className="">
        <div className="section-content">
          <div className="section-header center" data-aos="zoom-y-out" >
            <h2 className="">What you need to do</h2>
          </div>
          <div className=" col-2-md">
            <div data-aos-delay="100" data-aos="fade-left" >
              <img className="itap-hero-image" src={require('../images/woman-testing-at-home.svg').default} alt="Features bg" />
            </div>
            <div data-aos-delay="100" data-aos="fade-right">
              <p>If eligible, you will receive compensation of up to $25 in exchange for:
              <ul>
                  <li>Downloading an app on your smart phone and use to answer some questions about yourself.</li>
                  <li>Receiving all instructions and COVID-19 tests in the mail, at no cost to you.</li>
                  <li>Taking three types of COVID-19 tests over 1-2 days and using the app to answer some additional questions and log your test results.</li>
              </ul>
              </p>
            </div>
          </div> 
        </div>
        </section>





        <section className="dark-bg">
           <div className="section-content center">
             <div className="section-header" data-aos="zoom-y-out">
                <h2 className="">How to enroll</h2>

              <p className="">Enroll in this study to get at-home COVID-19 tests at no cost to you:</p>
              </div>
              <ol className="enrollment-instructions" data-aos="zoom-y-out">
                  <li>
                    <span><span>Step</span><span className="step-number">1</span></span>                  
                    <span className="step_content" style={{justifyContent:'space-evenly'}}>
                    <a href="https://apps.apple.com/us/app/mydatahelps/id1286789190"><img className="download-icon" src={appStoreButton} alt="Get on App Store" /></a>
                      <a href="https://play.google.com/store/apps/details?id=com.careevolution.mydatahelps&hl=en_US&gl=US"><img className="download-icon" src={googlePlayButton} alt="Download on Google Play" /></a>
                    </span>
                    <span>Download the MyDataHelps app</span>
                  </li>
                  <li>
                    <span><span>Step</span> <span className="step-number">2</span></span>
                    <span className="step_content">
                      <img src={require('../images/i-have-a-code-iphone.png').default} alt="Features bg" />
                    </span>
                    <span>Select "I have a project code"</span>
                  </li>
                  <li>
                    <span><span>Step</span> <span className="step-number">3</span></span>
                    <span className="step_content qr-code">
                      <img src={require('../images/download/qr-code-itap.png').default} alt="Features bg" />
                      <span>RCCZFS</span>
                    </span>
                    <span>Scan or enter QR code</span>
                  </li>
                  <li>
                    <span><span>Step</span> <span className="step-number">4</span></span>
                    <span className="step_content">
                      <img src={require('../images/mockup-mdh.png').default} alt="Features bg" />
                    </span>
                    <span>Complete the in-app instructions</span>
                  </li>
              </ol>
            </div>
          </section>
        {/* <section className="">
        <div className="section-content">
          <div className="section-header center" data-aos="zoom-y-out">
            <h2 className="">How it works</h2>
          </div>
          <div className=" col-2-md">
            <div data-aos-delay="100" data-aos="fade-right" >
              <p>{t('itap-a02-01')}
              <ul data-aos-delay="100" data-aos="fade-left">
                  <li>{t('itap-a02-02')}</li>
                  <li>{t('itap-a02-03')}</li>
                  <li>{t('itap-a02-04')}</li>
              </ul>
              </p>
            </div>

            <div data-aos-delay="100" data-aos="fade-left" >
              <img className="itap-hero-image" src={require('../images/woman-testing-at-home.svg').default} alt="Features bg" />
            </div>
          </div> 
        </div>
        </section> */}

        <section className="">
          <div className="section-content">

          <div className="section-header center" data-aos="zoom-y-out">
                <h2 data-aos="zoom-y-out" data-aos-delay="250">Frequently Asked Questions</h2>
              </div>
              <Accordion panels={ panels }/>
          </div>
        </section>


        <section className="dark-bg">
          <div className="section-content">
          <div className="section-header center" data-aos="zoom-y-out">
                <h2 className="">Participate in other at-home testing programs</h2>
                {/* <p className="">We offer at-home COVID-19 tests through a variety of different programs.</p> */}
            </div>
            <Programs />
          </div>
        </section>



        
      </main>
      <Footer />
      <DownloadApp code="RCCZFS" />

    </div>
  );
}

export default ITAP;