import React, { useEffect} from 'react';
import { Link } from 'react-router-dom';
import Language from '../partials/language';
import Programs from '../partials/Programs';
import DownloadApp from '../partials/downloadApp';
import { useTranslation } from 'react-i18next';
import TagManager from 'react-gtm-module';
import Footer from '../partials/Footer';
const docTitle = "STOP COVID-19"
const tagManagerArgs = {
    dataLayer: {
      event: 'page_view',
      page_location: document.location.href,
      page_title: docTitle
    }
}

function getFaviconEl() {return document.getElementById("favicon");}

function STOPCovid() {
  const { t } = useTranslation();

  TagManager.dataLayer(tagManagerArgs)
  useEffect(() => {
    document.title = docTitle; 
    const iTapFavicon = () => {
      const favicon = getFaviconEl();
      favicon.href = require('../images/logos/stop-favicon.ico').default
      console.log(favicon.href)
    };
    iTapFavicon()
  }, []);

  let language = ("uset",useTranslation().i18n.language)
  let appStoreButton, googlePlayButton;
  if (language === "en") {
    googlePlayButton = require('../images/download/android/en.svg').default;
    appStoreButton = require('../images/download/ios/en_black.svg').default;
  }
  if (language === "es") {
    googlePlayButton = require('../images/download/android/es.svg').default;
    appStoreButton = require('../images/download/ios/es_black.svg').default;
  }

  return (
    <div className="stop-covid flex flex-col min-h-screen overflow-hidden">
      <header className="">
          <nav className="">
            <Link className="nav-link" to="/stop-covid">
              <img src={require('../images/logos/stop-covid-full.svg').default} alt="ITAP logo" />
            </Link>
            <Language/>
          </nav>
      </header>
      <main className="flex-grow">
               
      <section className="" data-aos="zoom-y-out" data-aos-delay="250">
          <div className="section-content col-2-md">
            <div className="" data-aos-delay="250">
              <h1>{t('stop-title')}</h1>
              <p>{t('stop-header-1')}</p>
              <p>{t('stop-header-2')} <strong>STPCVD</strong> {t('stop-header-3')}</p>
              <div style={{display:'flex',flexDirection:'row'}}>  
                <a style={{marginRight:'.5rem'}} href="https://apps.apple.com/us/app/mydatahelps/id1286789190"><img className="download-icon" src={appStoreButton} alt="Get on App Store" /></a>
                <a href="https://play.google.com/store/apps/details?id=com.careevolution.mydatahelps&hl=en_US&gl=US"><img className="download-icon" src={googlePlayButton} alt="Download on Google Play" /></a>
              </div>
            </div>
            <div className="center">
              <img style={{maxWidth:'400px',marginBottom:'2rem',width:'100%'}} src={require('../images/logos/stop-logo-icon.svg').default} data-aos="zoom-y-out" alt="ITAP logo" />
            {/* <small>With support from the National Institutes of Health Rapid Acceleration of Diagnostics (RADxSM) initiative</small> */}
            </div>
          </div>
        </section>
        <section className="dark-bg">
          <div className="section-content">
            <div className=" col-2-md" >
              <div data-aos-delay="250" data-aos="fade-right" >
                <img className="stop-img" src={require('../images/stop/testing-matters.svg').default} alt="Features bg" />
              </div>
              <div data-aos-delay="250" data-aos="fade-left" >
              <h2 className="">{t('stop-q01')}</h2>
              <p>{t('stop-a01-01')} <strong>{t('stop-a01-02')} </strong>{t('stop-a01-03')}</p>
              </div>

            </div> 
          </div>
        </section>

        <section className="">
          <div className="section-content">
            <div className=" col-2-md">
              <div  data-aos-delay="250" data-aos="fade-right" >
              <h2 className="">{t('stop-q02')}</h2>
              <p><strong>{t('stop-a02-01')}</strong> {t('stop-a02-02')} </p>
              </div>

              <div data-aos-delay="250" data-aos="fade-left" >
                <img className="stop-img" src={require('../images/stop/responses.svg').default} alt="Features bg" />
              </div>
            </div> 
          </div>
        </section>

        <section className="dark-bg">
          <div className="section-content">

            <div className=" col-2-md">

            <div data-aos-delay="250" data-aos="fade-right" >
                <img className="stop-img" src={require('../images/stop/survey3.svg').default} alt="Features bg" />
              </div>
              <div  data-aos-delay="250" data-aos="fade-left" >
              <h2 className="">{t('stop-q03')}</h2>
              <p>{t('stop-a03-01')}</p>
              </div>

            </div> 
          </div>
        </section>

        <section className="">
          <div className="section-content">
            <div className=" col-2-md">
              <div data-aos-delay="250" data-aos="fade-right" >
              <h2 className="">{t('stop-q04')}</h2>
                <p> 
                  {t('stop-a04-01')}
                          <ul>
                            <li>{t('stop-a04-02')} <b>{t('stop-a04-03')}</b></li>
                  <li>{t('stop-a04-04')} <b>{t('stop-a04-05')}</b> {t('stop-a04-06')}</li>
                  <li>{t('stop-a04-07')} <b>{t('stop-a04-08')}</b></li>
                    </ul>
                    </p>
              </div>

              <div data-aos-delay="250" data-aos="fade-left" >
                <img className="stop-img" src={require('../images/stop/difference.svg').default} alt="Features bg" />
              </div>
            </div> 
          </div>
        </section>

        <section className="dark-bg">
          <div className="section-content">

            <div className=" col-2-md">

            <div data-aos-delay="250" data-aos="fade-right" >
                <img className="stop-img" src={require('../images/stop/responses.svg').default} alt="Features bg" />
              </div>
              <div  data-aos-delay="250" data-aos="fade-left" >
              <h2 className="">{t('stop-q05')}</h2>
              <p>{t('stop-a05-01')} <strong>{t('stop-a05-02')}</strong>.</p>
              </div>

            </div> 
          </div>
        </section>
        <section className="">
          <div className="section-content">

            <div className=" col-2-md">
              <div  data-aos-delay="250" data-aos="fade-right" >
              <h2 className="">{t('stop-q06')}</h2>
              <p><strong>{t('stop-a06-01')} </strong>{t('stop-a06-02')}</p>
              </div>

              <div data-aos-delay="250" data-aos="fade-left" >
                <img className="stop-img" src={require('../images/stop/gift.svg').default} alt="Features bg" />
              </div>
            </div> 
          </div>
        </section>

        <section className="dark-bg">
           <div className="section-content center">
             <div className="section-header" data-aos="zoom-y-out">
                <h2 className="">{t('stop-q07')}</h2>

              <p className="">{t('stop-a07-01')}</p>
              </div>
              <ol className="enrollment-instructions" data-aos="zoom-y-out">
                  <li>
                    <span><span>{t('stop-a07-03')}</span><span className="step-number">1</span></span>                  
                    <span className="step_content" style={{justifyContent:'space-evenly'}}>
                    <a href="https://apps.apple.com/us/app/mydatahelps/id1286789190"><img className="download-icon" src={appStoreButton} alt="Get on App Store" /></a>
                      <a href="https://play.google.com/store/apps/details?id=com.careevolution.mydatahelps&hl=en_US&gl=US"><img className="download-icon" src={googlePlayButton} alt="Download on Google Play" /></a>
                    </span>
                    <span>{t('stop-a07-04')}</span>
                  </li>
                  <li>
                    <span><span>{t('stop-a07-03')}</span> <span className="step-number">2</span></span>
                    <span className="step_content">
                      <img src={require('../images/i-have-a-code-iphone.png').default} alt="Features bg" />
                    </span>
                    <span>{t('stop-a07-05')}</span>
                  </li>
                  <li>
                    <span><span>{t('stop-a07-03')}</span> <span className="step-number">3</span></span>
                    <span className="step_content qr-code">
                      <img src={require('../images/stop/stop-qr.png').default} alt="QR Code" />
                      <span>STPCVD</span>
                    </span>
                    <span>{t('stop-a07-06')}</span>
                  </li>
                  <li>
                    <span><span>{t('stop-a07-03')}</span> <span className="step-number">4</span></span>
                    <span className="step_content">
                      <img src={require('../images/mockup-mdh.png').default} alt="Features bg" />
                    </span>
                    <span>{t('stop-a07-07')}</span>
                  </li>
              </ol>
            </div>
          </section>

          <section>
          <div className="section-content">
          <div className="section-header center" data-aos="zoom-y-out">
                <h2 className="">{t('stop-q08')}</h2>
                <p className="">{t('stop-a08-01')}</p>
            </div>
            <Programs />
          </div>
        </section>







        
      </main>

      <Footer />
      <DownloadApp code="STPCVD" />

    </div>
  );
}

export default STOPCovid;




