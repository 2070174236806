import React, { useEffect} from 'react';
import Header from '../partials/Header';
import Programs from '../partials/Programs';
import FeaturesBlocks from '../partials/FeaturesBlocks';
import Footer from '../partials/Footer';
import Wizard from '../partials/wizard/wizard';
import { useTranslation } from 'react-i18next';
import TagManager from 'react-gtm-module';
const docTitle = "At-home COVID tests from RADx"
const tagManagerArgs = {
  dataLayer: {
      event: 'page_view',
      page_location: document.location.href,
      page_title: docTitle
  }
}

function Home() {
  const { t } = useTranslation();
  TagManager.dataLayer(tagManagerArgs)
  useEffect(() => {
    document.title = docTitle; 
  }, []);
  return (
    <div className="flex flex-col min-h-screen overflow-hidden">

      {/*  Site header */}
      <Header />

      {/*  Page content */}
      <main className="flex-grow">
      <section className="center dark-bg" style={{paddingBottom: 0}}>
        <div className="center hero" data-aos="zoom-y-out">
            {/* <h1 className="font-extrabold aos-init aos-animate" data-aos="zoom-y-out">
              How to get at-home <span style={{whiteSpace: 'nowrap'}} >COVID-19 tests</span></h1> */}
              <h2>{t('home-hero-h')}</h2>
            <p>{t('home-hero-p')}</p>
            <div className="horiz-rule"></div>
            {/* <img style={{marginTop: '-5rem'}} src={require('../images/mask-people.svg').default} alt="illustration of people wearing masks" /> */}
          </div>
      </section>
      <section className="dark-bg wizard-wrap" data-aos="zoom-y-out">           
        {/* className='text-5xl md:text-6xl font-extrabold leading-tighter tracking-tighter mb-4'  */}
        {/* <WizardDemo/> */}
        <Wizard />
      </section>

      <section>
        <div id="compare-all" className="section-header center">
              <h2 className="" data-aos="zoom-y-out" data-aos-delay="100">About the test programs</h2>
              <p className="" data-aos="zoom-y-out" data-aos-delay="100">Select the program that's best for you.</p>
          </div>
        {/* <TableComponent /> */}
        <Programs />
      </section>
      <section className="dark-bg">
        <FeaturesBlocks />
      </section>
        {/*  Page sections */}
      <section className="col-2-md">
        <div>
          <img src={require('../images/woman-testing-at-home.svg').default} alt="Woman taking an at-home COVID test" />
        </div>
        <div>
          <div className="section-header">
            <h2 className="h2 mb-4" data-aos="zoom-y-out" data-aos-delay="100">Why test?</h2>
          </div>
          <ul data-aos="zoom-y-out" data-aos-delay="100">
              <li className="p2">Help reduce COVID-19 spread by testing at home and getting results in just 10 minutes</li>
              <li className="p2">People who don’t have symptoms can still spread COVID-19, so testing is critical to knowing if you're infected</li>
              <li className="p2">Along with vaccination, wearing a mask, hand washing, and physical distancing, frequent at-home testing protects our loved ones</li>
              <li className="p2">Know more, live safer, and get back to the people and things you love sooner</li>
          </ul>
        </div>
      </section>

      {/* <HeroHome /> */}
        {/* <Testimonials /> */}
        {/* <Newsletter /> */}

      </main>

      {/*  Site footer */}
      <Footer />

    </div>
  );
}

export default Home;