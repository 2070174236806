import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';
import Language from './language';

function Header() {
  // const { t } = useTranslation();
  const [top, setTop] = useState(true);

  // detect whether user has scrolled the page down by 10px 
  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true)
    };
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [top]);  

  return (
    // <header className={`fixed w-full z-30 md:bg-opacity-90 transition duration-300 ease-in-out ${!top && 'bg-white blur shadow-lg'}`}>
    <header>
          {/* Site navigation */}
          <nav style={{justifyContent:'center'}}>
            <Link className="nav-link" to="/">
              <img src={require('../images/logos/CHS-logo.svg').default} alt="free covid tests logo" style={{padding:'1rem 0', maxHeight:'200px', maxWidth:'300px'}} />
            </Link>
            <ul className="">
              {/* <Language/> */}
                {/* <Link className="nav-link" to="/">{t('home')}</Link>
                <Link className="nav-link" to="/page2">{t('page2')}</Link> */}
{/* <li>
                <Link to="/faq" className="center">ITAP FAQ</Link>
              </li> */}
              {/* <li>
                <Link to="/signup" className="btn-sm text-gray-200 bg-gray-900 hover:bg-gray-800 ml-3">
                  <span>Sign up</span>   
                </Link>
              </li> */}
            </ul>

          </nav>
    </header>
  );
}

export default Header;
