import { t } from 'i18next';
import React from 'react';
import Language from './language';
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t } = useTranslation();
  return (
    <footer>
      <p>{t('footer-h01')}
      </p>

      <div className="col-3-xs">
        {/* <a target="_blank" rel="noreferrer" href="/stop-covid" className="item">
          <img src={require('../images/logos/stop-covid-full.svg').default} alt="logo for the STOP Covid-19 program" />
        </a>
        <a target="_blank" rel="noreferrer" href="https://covid19testus.org/research-studies/test-us-at-home/how-to-enroll/" className="item">
          <img src={require('../images/logos/tuah.svg').default} alt="logo for Test Us at Home" />
        </a>
        <a target="_blank" rel="noreferrer" href="/itap" className="item">
          <img src={require('../images/logos/digital-itap-logo.svg').default} alt="logo for Digital ITAP" />
        </a> */}
        <span className="item"><img src={require('../images/logos/stop-covid-full.svg').default} alt="logo for the STOP Covid-19 program" /></span>
        <span className="item"><img src={require('../images/logos/tuah.svg').default} alt="logo for Test Us at Home" /></span>
        <span className="item"><img src={require('../images/logos/digital-itap-logo.svg').default} alt="logo for Digital ITAP" /></span>
      </div>
      <div className="col-3-xs">
        <a target="_blank" rel="noreferrer" href="https://www.nibib.nih.gov/" className="item">
          <img src={require('../images/logos/nibib-logo.png').default} alt="logo for NIH BIB" />
        </a>
        <a target="_blank" rel="noreferrer" href="https://www.nhlbi.nih.gov/" className="item">
          <img src={require('../images/logos/nihlbi-logo.png').default} alt="logo for NIH HLBI" />
        </a>
        <a target="_blank" rel="noreferrer" href="https://www.umassmed.edu/" className="item">
          <img src={require('../images/logos/umass-chan-logo.png').default} alt="logo for UMass Chan Medical School" />
        </a>
      </div>
      <p><small>{t('footer-h02')}</small>
      </p>
      <p className="copyright"><small>© {(new Date().getFullYear())} CareEvolution, LLC.</small></p>
      {/* <Language/> */}
    </footer>
  );
}
export default Footer;
