import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

function DownloadApp(props) {

  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    console.log(userAgent)
    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {return "Windows Phone";}
    if (/android/i.test(userAgent)) {return "android";}
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {return "ios";}
    // return "unknown";
    return "other";
  }
  var mobileOS = getMobileOperatingSystem();
  let language = ("uset",useTranslation().i18n.language)

  function hideDownloadApp (e) {
    console.log(e.target.closest('#download-app'))
    e.target.closest('#download-app').classList.add("hide")
  }

  // function copyToClipboard (){
  // }
      
  let buttonSrc, downloadUrl;
  if (mobileOS === "ios" && language === "en") {
    buttonSrc = require('../images/download/ios/en_black.svg').default;
    downloadUrl = "https://apps.apple.com/us/app/mydatahelps/id1286789190"
  }
  if (mobileOS === "ios" && language === "es") {
    buttonSrc = require('../images/download/ios/es_black.svg').default;
    downloadUrl = "https://apps.apple.com/us/app/mydatahelps/id1286789190"
  }
  if (mobileOS === "android"  && language === "en") {
    buttonSrc = require('../images/download/android/en.svg').default;
    downloadUrl = "https://play.google.com/store/apps/details?id=com.careevolution.mydatahelps&hl=en_US&gl=US"
  }
  if (mobileOS === "android"  && language === "es") {
    buttonSrc = require('../images/download/android/es.svg').default;
    downloadUrl = "https://play.google.com/store/apps/details?id=com.careevolution.mydatahelps&hl=en_US&gl=US"
  }
  if (mobileOS === "ios" || mobileOS === "android") {
    return (
      <div id="download-app">
        <div className="da-header">  
          <div onClick={hideDownloadApp} className="close-icon">
            <FontAwesomeIcon icon={faTimes} />
          </div> 
        </div>
        <div className="da-body">
          <div classNAme="download-app-step">
          <p>1. Install MyDataHelps</p> 
          <p className="center"><a href={downloadUrl}><img className="download-icon" src={buttonSrc} alt="Download MyDataHelps" /></a></p>
          </div>
          <div classNAme="download-app-step">
            <p>2. Enter QR Code:</p> 
            {/* <h2 onclick="copyToClipboard()">XDVGN</h2> */}
            <h2 className="download-icon">{props.code}</h2>
          </div>
        </div>
      </div>
    );
  } else {
    return (<></>)
  }
}

export default DownloadApp;