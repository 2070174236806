import React, { useEffect } from 'react';
import {
  Switch,
  Route,
  Redirect,
  useLocation
} from 'react-router-dom';
import './css/style.scss';
import AOS from 'aos';
import { focusHandling } from 'cruip-js-toolkit';
import Home from './pages/Home';
import ITAP from './pages/itap';
import STOPCovid from './pages/stop-covid';
import ITAPfeasibility from './pages/itap-feasibility';
import TagManager from 'react-gtm-module'
const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM
}
TagManager.initialize(tagManagerArgs)

function App() {
  const location = useLocation();

  useEffect(() => {
    AOS.init({
      once: true,
      disable: 'phone',
      duration: 700,
      easing: 'ease-out-cubic',
    });
  });

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto'
    window.scroll({ top: 0 })
    document.querySelector('html').style.scrollBehavior = ''
    focusHandling('outline');
  }, [location.pathname]); // triggered on route change

  return (
    <>
      <Switch>
        <Route exact path="/">
          <Home />
          {/* <Redirect to="/stop-covid" /> */}
        </Route>
        <Route exact path="/itap">
          <ITAP />
        </Route>
        {/* <Route exact path="/itap-feasibility">
          <ITAPfeasibility/>
        </Route> */}
        <Route exact path="/stop-covid">
          <STOPCovid/>
        </Route>
        {/* <Route path='*' exact={true}>
          <Redirect to="/stop-covid" />
        </Route> */}
        {/* <Route path='*' exact={true} component={Home} /> */}
      </Switch>
    </>
  );
}

export default App;
