import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

import StepWizard from "react-step-wizard";
import USAMap from "react-usa-map";
// import Map from "../map";

import usaData from "./states.json";

const usaStateNames = Object.values(usaData).map(n => n.name)
// let listOfElig = Object.values(usaData).filter(n => n.eligible == true)
  
const Wizard = (props) => {
    const [state, updateState] = useState({
        usState: '',
        symptoms: ''
    });

    var setUsState = function (value) {
        updateState({usState: value})
    }
    var setSymptoms = function (value1, value2) {
        updateState({
            usState: value2,
            symptoms: value1
        })
    }

    // let stateObj = Object.values(usaData).filter(n => n.name === usState)[0]
    // if( stateObj === undefined ){
    //     goToNamedStep('whatState')
    // }

    return (
        <div className="wizard">
            <StepWizard isHashEnabled >
                <WhatState stepName='whatState' setUsState={setUsState} />
                <RecentResults stepName='recentResults' usState={state.usState} />
                <Sharing stepName='sharing' usState={state.usState} symptoms={state.symptoms} />
                <AreYouSure stepName='areYouSure' usState={state.usState} symptoms={state.symptoms} />
                <JustTheTests stepName='justTheTests' usState={state.usState} />
                <Symptoms stepName='symptoms' usState={state.usState}  setSymptoms={setSymptoms} />
                <SymptomsYes stepName='symptomsYes' usState={state.usState} />
                <SymptomsNo stepName='symptomsNo' usState={state.usState} />
                <RecentlyPositive stepName='recentlyPositive' usState={state.usState} />
                <NoPrograms stepName='noPrograms' />
            </StepWizard>
        </div>
    );
};
export default Wizard;

        


const WhatState = ({
    setUsState,
    goToNamedStep,
    previousStep
}) => {
    var usState = 'default'
    var update = (e) => {
        var usState = e.target.value
        setUsState(usState)
        checkEligibility(usState)
        return usState;
    };
    var mapHandler = (event) => {
        usState = usaData[event.target.dataset.name].name
        setUsState(usState)
        checkEligibility(usState)
    };
    var checkEligibility = function(usState){
        let stateObj = Object.values(usaData).filter(n => n.name === usState)[0]
        if( stateObj.eligible === false ){
            goToNamedStep('noPrograms')
        } else {
            goToNamedStep('symptoms')
        }
    }
    return (
        <div className="wizard-step col-2-md">
            <div className="wizard-column-1">
                <div>
                    <h3>What state do you live in?</h3>
                    <p>Our test programs are currently only open to residents of the mainland United States (with the exception of AZ), but some programs have additional geographic limitations.</p>
                    <div>
                        <div className="form-group">
                            <select onChange={update} value={usState} name={usState} className="form-control">
                                <option value="default" disabled hidden>Select your state</option>
                                {usaStateNames.map(option => {
                                    return <option value={option} key={option} >{option}</option>
                                })}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wizard-column-2 center">
                <USAMap onClick={mapHandler} />
            </div>
        </div>
    );
};

const RecentResults = ({
    usState,
    goToNamedStep
}) => {
    let stateObj = Object.values(usaData).filter(n => n.name === usState)[0]
    if( stateObj === undefined ){
        goToNamedStep('whatState')
        return (<></>)
    } else {
        var checkEligibility = function(recentlyPos){
            if( recentlyPos === true ){
                goToNamedStep('recentlyPositive')
            } else if ( recentlyPos === false ) {
                goToNamedStep('symptoms')
            }
        }
        return (
            <div className="wizard-step col-2-md">
                <div className="wizard-column-1">
                    <div className="touchTarget center" onClick={() => goToNamedStep('whatState')}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </div>
                    <div className="wizard-text">
                        <h3>Have you tested positive for COVID-19 in the past month?</h3><p>This positive result could have been through an over-the-counter rapid antigen test, and does not need to have been an official PCR test.</p>
                        <div className="button-row">
                            <button className='button button_secondary' onClick={() => checkEligibility(true)}>Yes</button>
                            <button className='button button_secondary' onClick={() => checkEligibility(false)}>No</button>
                        </div>
                    </div>
                </div>
                <div className="wizard-column-2 center">
                    <img src={require('../../images/recent-test.svg').default} alt="illustration of rapid antigen and pcr covid tests" />
                </div>
            </div>
        );

    }
};

const Symptoms = ({
    usState,
    setSymptoms,
    setUsState,
    symptoms,
    goToNamedStep
}) => {
    let stateObj = Object.values(usaData).filter(n => n.name === usState)[0]
    if( stateObj === undefined ){
        goToNamedStep('whatState')
        return(<></>)
    } else {
        var checkEligibility = function(symptomatic){
            setSymptoms(symptomatic, usState)

            if( stateObj.syct === true ){
                goToNamedStep('sharing')
            } else {
                if( symptomatic === true ){
                    goToNamedStep('symptomsYes')
                } else if ( symptomatic === false ) {
                    goToNamedStep('symptomsNo')
                }
            }
        }
        return (
            <div className="wizard-step col-2-md">
                <div className="wizard-column-1">
                    <div className="touchTarget center" onClick={() => goToNamedStep('whatState')}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </div>
                    <div className="wizard-text">
                        <h3>Are you experiencing any symptoms?</h3><p>Symptoms may include fever or chills, cough, difficulty breathing, fatigue, headache, loss of taste or smell, sore throat, congestion, nausea, etc.</p>
                        <div className="button-row">
                            <button className='button button_secondary' onClick={() => checkEligibility(true)}>Yes</button>
                            <button className='button button_secondary' onClick={() => checkEligibility(false)}>No</button>
                        </div>
                    </div>
                </div>
                <div className="wizard-column-2 center">
                    <img src={require('../../images/symptoms.svg').default} alt="illustration of someone with various covid symptoms" />
                </div>
            </div>
        );
    }
};

const Sharing = ({
    usState,
    symptoms,
    goToNamedStep
}) => {
    let stateObj = Object.values(usaData).filter(n => n.name === usState)[0]
    if( stateObj === undefined ){
        goToNamedStep('whatState')
        return(<></>)
// TODO:
        // } else if ( symptoms === undefined ) {
    //     goToNamedStep('symptoms')
    //     return(<></>)
    } else {
        var syctPath = function(share){
            if( share === 'shareNo' ){
                goToNamedStep('areYouSure')
            } else if ( share === 'shareYes'){
                if( symptoms === true ){
                    goToNamedStep('symptomsYes')
                } else if ( symptoms === false ) {
                    goToNamedStep('symptomsNo')
                }
            }
        }
    
        return (
            <div className="wizard-step col-2-md">
                <div className="wizard-column-1">
                    <div className="touchTarget center" onClick={() => goToNamedStep('whatState')}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </div>
                    <div>
                        <h3>Are you willing to share your information with researchers{usState ? ' in '+usState : ''}?</h3>
                        <p>Participation in research is not required for all programs, but it is a big help to your community and some studies offer up to $100 for your time.</p>
                        <div className="button-row">
                            <button className='button' onClick={() => syctPath('shareYes')}>Yes</button>
                            <button className='button button_secondary' onClick={() => syctPath('shareNo')}>No</button>
                        </div>
                    </div>
                </div>
                <div className="wizard-column-2 center">
                    <img src={require('../../images/scientists-06.svg').default} alt="illustration of people wearing masks" />
                </div>
            </div>
        );
    }
};

const AreYouSure = ({
    usState,
    symptoms,
    goToNamedStep
}) => {
    let stateObj = Object.values(usaData).filter(n => n.name === usState)[0]
    if( stateObj === undefined ){
        goToNamedStep('whatState')
        return(<></>)
// TODO:
        // } else if ( symptoms === undefined ) {
    //     goToNamedStep('symptoms')
    //     return(<></>)
    } else {
        var syctPath = function(share){
            if( share === 'shareNo' ){
                goToNamedStep('justTheTests')
            } else if ( share === 'shareYes'){
                if( symptoms === true ){
                    goToNamedStep('symptomsYes')
                } else if ( symptoms === false ) {
                    goToNamedStep('symptomsNo')
                }
            }
        }
    
        return (
            <div className="wizard-step col-2-md">
                <div className="wizard-column-1">
                    <div className="touchTarget center" onClick={() => goToNamedStep('sharing')}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </div>
                    <div className="wizard-text">
                        <h3>Are you sure?</h3>
                        <p>Your data will only be shared with researchers working to stop the spread of COVID-19, and some studies offer up to $100 for participation.</p>
                        <div className="button-column">
                            <button className='button button_secondary' onClick={() => syctPath('shareNo')}>I just want the tests</button>
                            <button className='button' onClick={() => syctPath('shareYes')}>I'd like to help researchers</button>
                        </div>
                    </div>
                </div>
                <div className="wizard-column-2 center">
                    <img src={require('../../images/scientists-07.svg').default} alt="illustration of people wearing masks" />
                </div>
            </div>
        );
    }
};



//END STEPS

const SymptomsYes = ({
    usState,
    props,
    goToNamedStep
}) => {

    // TODO: refactor this to fix console error
    let stateObj = Object.values(usaData).filter(n => n.name === usState)[0]
    if( stateObj === undefined ){
        goToNamedStep('whatState')
        return(<></>);
    } else {
        return (
            <div className="wizard-step col-2-md">
                <div className="wizard-column-1">
                    <div className="touchTarget center" onClick={() => goToNamedStep('symptoms')}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </div>
                    <div className="wizard-text center">
                        <h3 className='text-center'>Best Match</h3>
                        <p>You may qualify for tests through <strong>Digital ITAP</strong>. In addition to the tests, this study also offers up to a $25 incentive. Learn more:</p>                    <div style={{display:'flex',justifyContent:'center'}}>
                            <Link className="program-logo" target="_blank" to="/itap">
                                <img src={require('../../images/logos/digital-itap-logo.svg').default} alt="logo for the ITAP program" />
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="wizard-column-2 center">
                <h3 className='text-center'>Other Opportunities</h3>
                <p><span style={{display:'block'}}>You may also qualify for these other test programs.</span>
                        <a href="#compare-all" style={{textDecoration:'underline'}}>Compare all</a></p>
                    <div className="program-logos">
                        <a target="_blank" rel="noreferrer" href="/stop-covid" className="program-logo">
                            <img src={require('../../images/logos/stop-covid-full.svg').default} alt="logo for the STOP Covid-19 program" />
                        </a>
                        {stateObj.syct &&
                            <a className="program-logo" href="https://sayyeshometest.org/" target="_blank" rel="noreferrer"><img src={require('../../images/logos/sayyes-horizontal.png').default} alt="logo for the Say Yes! Covid Tests program" /></a>
                        }
                    </div>
                </div>
            </div>
        );
    }
};
const SymptomsNo = ({
    usState,
    props,
    goToNamedStep
}) => {

    // TODO: refactor this to fix console error
    let stateObj = Object.values(usaData).filter(n => n.name === usState)[0]
    if( stateObj === undefined ){
        goToNamedStep('whatState')
        return(<></>);
    } else {
        return (
            <div className="wizard-step col-2-md">
                <div className="wizard-column-1">
                    <div className="touchTarget center" onClick={() => goToNamedStep('symptoms')}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </div>
                    <div className="wizard-text center">
                        <h3 className='text-center'>Best Match</h3>
                        <p>You may qualify for tests through <strong>STOP COVID-19</strong>. In addition to the tests, this study also offers up to a $100 incentive. Learn more:</p>
                        <div style={{display:'flex',justifyContent:'center'}}>
                            <a target="_blank" rel="noreferrer" href="/stop-covid" className="program-logo">
                                <img src={require('../../images/logos/stop-covid-full.svg').default} alt="logo for the Test Us at Home program" />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="wizard-column-2 center">
                    <h3 className='text-center'>Other Opportunities</h3>
                    <p><span style={{display:'block'}}>You may also qualify for these other test programs.</span>
                        <a href="#compare-all" style={{textDecoration:'underline'}}>Compare all</a></p>
                    <div className="program-logos">
                        <Link className="program-logo" target="_blank" to="/itap">
                            <img src={require('../../images/logos/digital-itap-logo.svg').default} alt="logo for the ITAP program" />
                        </Link>
                        {/* <a target="_blank" rel="noreferrer" href="/stop-covid" className="program-logo">
                            <img src={require('../../images/logos/stop-covid-full.svg').default} alt="logo for the STOP Covid-19 program" />
                        </a> */}
                        {stateObj.syct &&
                            <a className="program-logo" href="https://sayyeshometest.org/" target="_blank" rel="noreferrer"><img src={require('../../images/logos/sayyes-horizontal.png').default} alt="logo for the Say Yes! Covid Tests program" /></a>
                        }
                    </div>
                </div>
            </div>
        );
    }
};

const RecentlyPositive = ({
    usState,
    props,
    goToNamedStep
}) => {
    let stateObj = Object.values(usaData).filter(n => n.name === usState)[0]
    if( stateObj === undefined ){
        goToNamedStep('whatState')
        return (<></>)
    } else {
        return (
            <div className="wizard-step col-2-md">
                <div className="wizard-column-1">
                    <div className="touchTarget center" onClick={() => goToNamedStep('recentResults')}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </div>
                    <div className="wizard-text center">
                        <h3 className='text-center'>Best Match</h3>
                        <p>You may qualify for tests through <strong>STOP COVID-19</strong>. In addition to the tests, this study also offers up to a $100 incentive. Learn more:</p>
                        <div style={{display:'flex',justifyContent:'center'}}>
                            <a target="_blank" rel="noreferrer" href="/stop-covid" className="program-logo">
                            <img src={require('../../images/logos/stop-covid-full.svg').default} alt="logo for the STOP Covid-19 program" />
                            </a>
                        </div>
                    </div>
                </div>
                    {stateObj.syct &&
                        <div className="wizard-column-2 center">
                            <h3 className='text-center'>Other Opportunities</h3>
                            <p><span style={{display:'block'}}>You may also qualify for these other test programs.</span>
                                <a href="#compare-all" style={{textDecoration:'underline'}}>Compare all</a></p>
                            <div className="program-logos">
                                <a className="program-logo" href="https://sayyeshometest.org/" target="_blank" rel="noreferrer"><img src={require('../../images/logos/sayyes-horizontal.png').default} alt="logo for the Say Yes! Covid Tests program" /></a>
                            </div>
                        </div>
                        
                    }
                    {!stateObj.syct &&
                        <div className="wizard-column-2 center">
                            <img src={require('../../images/wear-masks.svg').default} alt="illustration of people wearing masks" /> 
                        </div>
                    }
            </div>
        );

    }
};

const JustTheTests = ({
    usState,
    props,
    goToNamedStep
}) => {

    // TODO: refactor this to fix console error
    // let stateObj = Object.values(usaData).filter(n => n.name === usState)[0]
    // if( stateObj === undefined ){
    //     goToNamedStep('whatState')
    // }
    
   

    return (
        <div className="wizard-step col-2-md">
            <div className="wizard-column-1">
                <div className="touchTarget center" onClick={() => goToNamedStep('sharing')}>
                    <FontAwesomeIcon icon={faArrowLeft} />
                </div>
                <div className="wizard-text">
                    <h3>Best Match</h3>
                    <p>{usState ? 'Because you live in '+usState : 'Based on the information provided'}, you may qualify for tests through the <strong>Say Yes! Covid Test</strong> program. Find out if your zip code is eligible:</p>
                    <div style={{display:'flex',justifyContent:'center'}}>
                        <a className="program-logo" href="https://sayyeshometest.org/" target="_blank" rel="noreferrer"><img src={require('../../images/logos/sayyes-horizontal.png').default} alt="logo for the Say Yes! Covid Tests program" /></a>
                    </div>
                </div>
            </div>
            <div className="wizard-column-2 center">
                <img src={require('../../images/wear-masks.svg').default} alt="illustration of people wearing masks" />
            </div>
        </div>
    );
};

const NoPrograms = ({
    usState,
    props,
    goToNamedStep
}) => {

    // TODO: refactor this to fix console error
    // let stateObj = Object.values(usaData).filter(n => n.name === usState)[0]
    // if( stateObj === undefined ){
    //     goToNamedStep('whatState')
    // }
    
   
    return (
        <div className="wizard-step col-2-md">
            <div className="wizard-column-1">
                <div className="touchTarget center" onClick={() => goToNamedStep('whatState')}>
                    <FontAwesomeIcon icon={faArrowLeft} />
                </div>
                <div className="wizard-text">
                    <h3>Thank you for your interest!</h3>
                    <p>Based on the information provided, you are not currently eligible for any of our programs. Please contact your local health department for more information.</p>
                    
                </div>
            </div>
            <div className="wizard-column-2 center">
                <img src={require('../../images/wear-masks.svg').default} alt="illustration of people wearing masks" />
            </div>
        </div>
    );
};




// class SelectBox2 extends React.Component {
// //    TODO: consider replacing select with this component

//     constructor(props) {
//         super(props);
//         this.state = { value: 'Select an Option'};
//         console.log("constructor this", this)
//     }
//     selectUsState(e) {
//         var selectedState = e.target.value
//         this.setState({
//             value: selectedState
//         })
//         console.log("selectedState", selectedState)
//         return selectedState;
//     }
//     render() { 
//         return (
//         <>
//             <div className="form-group" style={{border:'1px solid red'}}>
//                 {/* <label htmlFor="select2" >Select2</label> */}
//                 <select value={this.state.value} onChange={this.selectUsState.bind(this)} className="form-control">
//                 {/* {usStates.map(option => {
//                     return <option value={option} key={option} >{option}</option>
//                 })} */}
//                 <option value="default" disabled hidden>Select your state</option>
//                 {usaStateNames.map(option => {
//                     return <option value={option} key={option} >{option}</option>
//                 })}
//                 </select>
//                 {/* <p>{this.state.value}</p> */}
//             </div>
//             {/* <USAMap onClick={this.mapHandler} /> */}

//         </>
//     )}
// }
